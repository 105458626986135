<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="filmType.name">Film Type Settings</span>
        <span class="headline" v-else>New Film Type</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <v-text-field v-model="filmType.name" label="SKU"
                :rules="rules.required" class="form-item"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.number="filmTypeSettings.length" label="Roll length"
                :rules="rules.required" class="form-item" suffix='m'></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.number="filmTypeSettings.thickness" label="Film thickness"
                :rules="rules.required" suffix="µm" class="form-item"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.number="filmTypeSettings.width" label="Roll width"
                 suffix="mm" class="form-item" ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.number="filmTypeSettings.weightPerVolume" label="Weight per volume"
                :rules="rules.required" suffix="kg/m³" class="form-item"></v-text-field>
              <span v-if="weightPerMeter">Weight per meter: {{weightPerMeter()}} g/m</span>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">{{snackbarMessage}}</v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'FilmTypeSettings',
    components: {},
    computed: {},
    data: () => ({
      isLoaded: false,
      isValid: true,
      customerId:null,
      filmTypeId: null,
      snackbar: false,
      snackbarMessage: '',
      assetGroupId: '72543f90-61de-11eb-aba8-29b524c0c604', // asset group for all film types
      filmType: {
        name: '',
        additionalInfo: {
          description:''
        }
      },
      filmTypeSettings: {
        length: 0,
        thickness: 0,
        weightPerMeter: 0,
        weightPerVolume: 0,
        width: 0,
      },
      rules: {
        required: [val => {
          return (val) ? true : 'Required'
        }]
      }
    }),
    methods: {
      load() {
        this.isLoaded = true
      },
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      weightPerMeter(){
        const weightPerVolume = this.filmTypeSettings.weightPerVolume // kg/m3
        const width = this.filmTypeSettings.width/1000 // meters
        const thickness = this.filmTypeSettings.thickness/1000000 // meters
        const weightPerMeter = weightPerVolume * width * thickness // kg/m
        this.filmTypeSettings.weightPerMeter = Math.round(1000*weightPerMeter) // g/m
        return  this.filmTypeSettings.weightPerMeter
      },
      setSnackbarMessage(message){
        this.snackbarMessage = message
        this.snackbar = true
      },
      save() {
        const _this = this
        _this.validateForm()
        if (_this.isValid) {
          const data = {
            name: _this.filmType.name,
            type: "Wrap Type"
          }
          if (_this.filmTypeId != null) {
            data.id = {entityType: "ASSET", id:_this.filmTypeId}
          }
          _this.$tb.post('asset?entityGroupId='+ _this.assetGroupId, data).then((res)=>{
            if (res.status == 200) {
              _this.filmTypeId = res.data.id.id
              _this.$tb.post('plugins/telemetry/ASSET/' + _this.filmTypeId + '/attributes/SERVER_SCOPE', _this.filmTypeSettings).then((res2)=>{
                if (res2.status == 200) {
                  _this.setSnackbarMessage("Saved successfully")
                  _this.$router.push('/film-types')
                }
                else {
                  _this.setSnackbarMessage("Failed to save")
                }
              })
            }
            else {
              _this.setSnackbarMessage("Failed to save")
            }
          })
        }
        else {
          console.log('Invalid form')
          return false
        }
      },
    },
    mounted() {
      const _this = this
      if (this.$route.path.endsWith('/add')) {
        _this.isLoaded=true
      }
      else {
        this.filmTypeId = this.$route.params.id

        this.$tb.get('asset/' + this.filmTypeId).then((res)=>{
          _this.filmType = res.data
          this.$tb.get('plugins/telemetry/ASSET/' + this.filmTypeId + '/values/attributes').then((res2)=>{
            res2.data.forEach((item) => {
              const key = item.key
              const val = item.value
              _this.filmTypeSettings[key] = val
            })
            _this.isLoaded=true
          })
        })
      }
    }
  }
</script>

<style scoped>
.form-item {
  max-width:500px
}
</style>