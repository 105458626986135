<template>
   <v-app>
      <v-navigation-drawer permanent expand-on-hover app touchless :mini-variant.sync="drawer">
        <v-list nav dense>

          <v-list-item link to="/sensors">
            <v-list-item-icon>
              <v-icon>mdi-cellphone-link</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sensors</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/customers">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/film-types">
            <v-list-item-icon>
              <v-icon>mdi-paper-roll</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Film Types</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/firmware">
            <v-list-item-icon>
              <v-icon>mdi-harddisk</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Firmware</v-list-item-title>
          </v-list-item>


        </v-list>
        <div class="small" v-if="!drawer">Admin tool version: {{$version}}</div>
      </v-navigation-drawer>
  <v-main>
    <router-view />
  </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: true,
  }),
};
</script>

<style scoped>
.small {
  font-size: smaller;
}
</style>