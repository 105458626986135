<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline">Customers</span>
      </v-card-title>
      <v-card-text>
        <p>This page enables wrappers to be configured for each customer. To set up new customers follow the customer setup guide.</p>
        <v-data-table :headers="headers" :items="customers" :items-per-page="500" hide-default-footer :sort-by="['name']">
          <template v-slot:item.id="{ item }">
            <v-btn :to="'/customer/'+item.id+'/wrappers'" icon>
              <v-icon>mdi-domain</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Customers',
    components: {},
    computed: {
    },
    data: () => ({
      isLoaded: false,
      headers: [
        {text: 'Customer', sortable: true, value: 'name'},
        {text: 'Wrappers', sortable: false, value: 'id'}
      ],
      customers:[],
    }),
    methods: {

    },
    mounted() {
      const _this = this
      this.$tb.get('customers', {pageSize: 100, page:0}).then((res)=>{
        this.customers = []
        res.data.data.forEach((item)=>{
          this.customers.push({id:item.id.id,
                               name: item.name
                              })
        })
        _this.isLoaded = true
      })

    }
  }
</script>

<style scoped>

</style>