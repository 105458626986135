<template>
    <v-card v-if="isLoaded">
      <v-card-title>
        <span class="headline">Wrapper Calibration</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <v-text-field v-model="wrapWeight" label="Wrap weight"
                :error-messages="errors" class="form-item" suffix='g'></v-text-field>
                <Tooltip tooltip="Weight of wrap used during a test wrap" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select v-model="reading" label="Reading" :items="telemetryOptions" item-text="text" item-value="value"
                :error-messages="errors" class="form-item"></v-select>
                <Tooltip tooltip="The reading which to use for calibration purposes" />
            </v-col>
          </v-row>

          <div v-if="correctionFactor && reading">Correction factor: {{correctionFactor}}</div>
          <div class="warn">Please only use readings which have been gathered while the correction factor was set to 1.0</div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  import Tooltip from '@/components/Tooltip'

  export default {
    name: 'WrapperCalibration',
    components: {Tooltip},
    props:['wrapper'],
    computed: {
      isLoaded(){
        return this.telemetryLoaded
      },
      telemetryOptions(){
        const options = []
        this.telemetry.forEach((item) => {
          const time = new Date(item.ts)
          const text = time.toLocaleString() + ' (' + item.value + 'g)'
          options.push({'text': text, 'value': item.ts})
        })
        return options
      },
      correctionFactor() {
        const value = this.telemetryLookup(this.reading)
        return Math.round(100*this.wrapWeight / value)/100
      },
    },
    watch: {
    },
    data: () => ({
      isValid: true,
      errors:'',
      telemetry: [],
      reading: 0,
      telemetryLoaded:false,
      wrapWeight: ''
    }),
    methods: {
      getLatestTelemetry(){
        const _this = this
        const d = new Date()
        const end = d.getTime()
        d.setDate(d.getDate()-10)
        const start = d.getTime()
        const limits = {keys:'wrapWeight', startTs: start, endTs:end,limit:5000}
        console.log(limits)
        this.$tb.get('plugins/telemetry/ASSET/' + this.wrapper.id.id + '/values/timeseries', limits).then((res)=>{
          if (!res.data.wrapWeight) {
            _this.telemetryLoaded=true
          }
          else {
            _this.telemetry = res.data.wrapWeight
            _this.telemetryLoaded=true
            console.log( _this.telemetry.length)
          }
        })
      },
      telemetryLookup(timestamp){
        let value = 0
        this.telemetry.forEach(item => {
          if (item.ts === timestamp) {
            value = item.value
          }
        })
        return value
      },
      save() {
        const _this = this
        const data = {correctionFactor: this.correctionFactor}
        this.$tb.post('plugins/telemetry/ASSET/' + this.wrapper.id.id + '/attributes/SERVER_SCOPE', data).then((res)=>{
          if (res.status == 200) {
            _this.$emit('close')
          }
        })
      },
    },
    mounted() {
      if (this.wrapper !== null) {
        this.getLatestTelemetry()
      }
    }
  }
</script>

<style scoped>
.form-item {
  width: 360px;
  display: inline-block;
}

.warn {
  color: red;
}
</style>