<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline">Firmware</span>
      </v-card-title>

      <v-card-text>
        <p>This tool updates the firmware on ALL sensors. Use with care.</p>
        <v-form ref="form" v-model="isValid">
          <v-select v-model="selectedFirmware" label="Firmware version" :items="availableFirmware"
            class="form-item" :rules="rules.required"></v-select>
          <Tooltip tooltip="The firmware which will be updated across all sensors when they next check in" />
          <div>New firmware files should be uploaded directly into the firmware AWS S3 bucket.</div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="!isValid" color="primary" @click="update">Update all sensors</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import Tooltip from '@/components/Tooltip'
  import {getFirmwareVersions} from '@/lib/aws'

  export default {
    name: 'Firmware',
    s3: undefined,
    components: {Tooltip},
    computed: {
    },
    data: () => ({
      isLoaded:true,
      isValid: true,
      availableFirmware: [],
      selectedFirmware: '',
      deviceEntityGroupId: '7fcaafb0-3ff1-11eb-9025-c964801605d3', // all devices live here
      rules: {
        required: [val => {
          return (val) ? true : 'Required'
        }]
      }
    }),
    methods: {
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      update(){
        const _this = this
        this.$tb.get('entityGroup/'+ this.deviceEntityGroupId +'/devices', {pageSize: 1000, page:0}).then((res)=>{
          res.data.data.forEach(device => {
            const deviceId = device.id.id
            const attributes = {firmwareVersionAvailable: _this.selectedFirmware}
            this.$tb.post('plugins/telemetry/DEVICE/' + deviceId + '/attributes/SHARED_SCOPE', attributes).then((res2)=>{
              console.log(res2)
            })
          })
        })
      }
    },
    mounted() {
      const _this = this
      getFirmwareVersions().then((fw) => {
        _this.availableFirmware = fw
      })
    }
  }

</script>

<style scoped>
.form-item {
  width: 260px;
  display: inline-block;
}

</style>