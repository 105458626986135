/**
 * Usage:
 * in main.js:
 * import Thingsboard from "./plugins/thingsboard";
 * Vue.use(Thingsboard);
 *
 * in components:
 * try {
 *  response = await this.$tb.get('/tenant/123')
 * }
 * catch(err) {
 *   console.log(err)
 * }
 * OR
 * this.$tb.get('/tenant/123').then((response) => {
 *
 * }).catch((err) => {
 *   console.log(err)
 * })
 */

import jwt_decode from "jwt-decode";
import axios from 'axios'

export default {
  install(Vue) {
    Vue.prototype.$tb = {
      baseUrl: 'https://wrapwell.universalpackaging.co.nz/api',
      jwt: '', // JWT used for subsequent requests
      data: { // JWT data
          sub: "",// Email address
          scopes: [], // Array of scoped eg. "TENANT_ADMIN"
          userId: "", // UUID for the user
          firstName: "", // First name
          lastName: "", // Last name
          enabled: false, // ? Is the user enabled
          isPublic: false, // ? Is the user public
          tenantId: "", // UUID the tenant the user belongs to
          customerId: "", // UUID the customer belongs to
          iss: "", // JWT source (as reported by th JWT)
          iat: 0, // ??
          exp: 0, // JWT expiry date
      },
    }

    Vue.prototype.$tb.authorize = async () =>{
      if (process.env.VUE_APP_AUTHORIZATION_METHOD === 'URL') {
         return await Vue.prototype.$tb.authorizeViaURL()
      }
      else if (process.env.VUE_APP_AUTHORIZATION_METHOD === 'LOGIN') {
        return await Vue.prototype.$tb.authorizeViaLogin()
      }
      else {
        console.log('Unknown Authorization method')
      }
    }

    Vue.prototype.$tb.authorizeViaURL = async () =>{
      const urlParams = new URLSearchParams(window.location.search)
      Vue.prototype.$tb.jwt = urlParams.get('accessToken')
      Vue.prototype.$tb.data = jwt_decode(Vue.prototype.$tb.jwt)
    }

    Vue.prototype.$tb.authorizeViaLogin = async () =>{
      const data = {
        username: process.env.VUE_APP_THINGBOARD_USERNAME,
        password: process.env.VUE_APP_THINGBOARD_PASSWORD,
      }
      const response = await axios.post(Vue.prototype.$tb.baseUrl + '/auth/login', data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      Vue.prototype.$tb.jwt = response.data.token
      Vue.prototype.$tb.data = jwt_decode(Vue.prototype.$tb.jwt)
    }

    Vue.prototype.$tb.get = async (url, params) => {
      if (Vue.prototype.$tb.jwt == '') {
        await Vue.prototype.$tb.authorize()
      }
      return await axios.get(Vue.prototype.$tb.baseUrl + '/' + url, {
        params:params,
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': 'Bearer ' + Vue.prototype.$tb.jwt
        }
      })
    }

    Vue.prototype.$tb.post = async (url, data) => {
      return await axios.post(Vue.prototype.$tb.baseUrl + '/' + url, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': 'Bearer ' + Vue.prototype.$tb.jwt
        }
      })
    }

    Vue.prototype.$tb.put = async (url, data) => {
      return await axios.put(Vue.prototype.$tb.baseUrl + '/' + url, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': 'Bearer ' + Vue.prototype.$tb.jwt
        }
      })
    }

    Vue.prototype.$tb.delete = async (url, params) => {
      return await axios.delete(Vue.prototype.$tb.baseUrl + '/' + url, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': 'Bearer ' + Vue.prototype.$tb.jwt
        }
      })
    }
  }
}