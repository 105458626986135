<template>
  <v-card v-if="isLoaded">
    <v-card-title>
      <span class="headline" v-if="wrapper">{{wrapperName}}</span>
      <span class="headline" v-else>New wrapper</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col>
            <v-text-field v-model="wrapperName" label="Wrapper Name"
              :rules="rules.required" class="form-item"></v-text-field>
              <Tooltip tooltip="The name of the wrapper as it will appear on the customers dashboard" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
             <v-select v-model="wrapperSettings.filmType" label="Film type" :items="filmTypes" item-text="name" item-value="name"
              class="form-item"></v-select>
              <Tooltip tooltip="The type of wrap used on the wrapper" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="wrapperSettings.minTargetWeightPerPallet" label="Target weight of wrap per pallet (lower limit)"
             suffix="g" class="form-item"></v-text-field>
             <Tooltip tooltip="The lower bound of the ideal wrap weight (as shown on the dashboard)" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="wrapperSettings.maxTargetWeightPerPallet" label="Target weight of wrap per pallet (upper limit)"
              suffix="g" class="form-item"></v-text-field>
              <Tooltip tooltip="The upper bound of the ideal wrap weight (as shown on the dashboard)" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="wrapperSettings.correctionFactor" label="Correction factor"
             class="form-item"></v-text-field>
             <Tooltip tooltip="If the wrap does not run perpendicular to the wrap a correction factor may be needed. The default value (no correction) is 1." />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="wrapperSettings.finalWrapLength" label="Final Wrap length"
             suffix="m" class="form-item"></v-text-field>
             <Tooltip tooltip="This is the final length of wrap expected for a pallet and is obtained by weighing the wrap. The purpose of inputting this figure is to calculate the stretch. Set to 0 to disable calculating stretch" />
          </v-col>
        </v-row>

      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cancel</v-btn>

      <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Tooltip from '@/components/Tooltip'

  export default {
    name: 'WrapperSettings',
    components: {Tooltip},
    props:['wrapper', 'customerId'],
    computed:{

    },
    data: () => ({
      isLoaded: false,
      isValid: true,
      filmGroupId: '72543f90-61de-11eb-aba8-29b524c0c604', // Film
      wrapperId:'',
      wrapperSettings: {
        filmType: '',
        minTargetWeightPerPallet: 0,
        maxTargetWeightPerPallet: 0,
        wrapperName: '',
        finalWrapLength: 0,
        correctionFactor: 1.0
      },
      filmTypes:[],
      rules: {
        required: [val => {
          return (val) ? true : 'Required'
        }]
      }
    }),
    methods: {
      clear(){
        this.wrapperSettings = {
          filmType: '',
          minTargetWeightPerPallet: 0,
          maxTargetWeightPerPallet: 0,
          finalWrapLength: 0,
          correctionFactor: 1.0,
          wrapperName:''
        }
      },
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      getFilmTypes(){
        const _this = this
        this.$tb.get('entityGroup/' + this.filmGroupId + '/assets', {pageSize: 100, page:0, type:"Roll"}).then((res)=>{
          _this.filmTypes = []
          res.data.data.forEach((item)=>{
            const details = {name:item.name, id: item.id.id}
            this.$tb.get('plugins/telemetry/ASSET/' + item.id.id + '/values/attributes').then((res2)=>{
              res2.data.forEach((item) => {
                const key = item.key
                const val = item.value
                details[key] = val
              })
              _this.filmTypes.push(details)
            })
            _this.filmTypesLoaded=true
          })
        })
      },
      getFilmWeight(){
        const _this = this
        let filmWeight = 0
        _this.filmTypes.forEach(filmType => {
          if (filmType.name == _this.wrapperSettings.filmType) {
            filmWeight = filmType.weightPerMeter
          }
        })
        return filmWeight
      },
      saveSettings(){
        const _this = this
        _this.wrapperSettings.filmWeight = _this.getFilmWeight()

        // save wrapper name
        const data = {...this.wrapper}
        data.name = this.wrapperName

        // save name
        _this.$tb.post('asset', data).then(()=>{
          // save the rest
          _this.$tb.post('plugins/telemetry/ASSET/' + this.wrapperId + '/attributes/SERVER_SCOPE', this.wrapperSettings).then(()=>{
            _this.$emit('close')
          })
        })
      },
      save() {
        const _this = this
        this.validateForm()
        if (this.isValid) {
          if (this.wrapper === null) { // new wrapper
            console.log('entityGroup/all/CUSTOMER/'+ this.customerId +'/ASSET')
            _this.$tb.get('entityGroup/all/CUSTOMER/'+ this.customerId +'/ASSET').then((res2)=>{
              const entityGroupId = res2.data.id.id
              const data = {
                              name: _this.wrapperName,
                              type: "Wrapper"
                           }

              _this.$tb.post('asset?entityGroupId='+entityGroupId, data).then((res)=>{
                _this.wrapperId = res.data.id.id
                _this.wrapper = res.data // this mutates the prop (not ideal)
                _this.saveSettings()
              })
            })
          }
          else { // just save without creating first
            _this.saveSettings()
          }
        }
        else {
          return false
        }
      },
    },
    mounted() {
      const _this = this

      // load wrapper details
      if (_this.wrapper !== null) {
          _this.wrapperId = _this.wrapper.id.id
          _this.$tb.get('plugins/telemetry/ASSET/' + _this.wrapperId + '/values/attributes').then((res2)=>{
            res2.data.forEach((item) => {
              const key = item.key
              const val = item.value
              _this.wrapperSettings[key] = val
            })
            _this.isLoaded=true
            _this.wrapperName = this.wrapper.name
          })
      }
      else {
          _this.clear()
          _this.isLoaded=true
          this.wrapperName = ''
      }
      this.getFilmTypes()
    }
  }
</script>

<style scoped>
.form-item {
  width: 260px;
  display: inline-block;
}
</style>