<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline">Film Types</span>
      </v-card-title>

      <v-card-text>
         <v-btn :to="'/film-type/add'"><v-icon>mdi-plus</v-icon>Add Film Type</v-btn>
         <v-data-table :headers="headers" :items="filmTypes" :items-per-page="500" hide-default-footer :sort-by="['name']">
           <template v-slot:item.id="{ item }">
             <v-btn :to="'/film-type/'+item.id+'/edit'" icon>
               <v-icon>mdi-pencil</v-icon>
             </v-btn>
             <v-btn @click="selectedId=item.id; dialog = true" color="red" icon>
               <v-icon>mdi-delete</v-icon>
             </v-btn>
           </template>
         </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title>
          Are you sure you want to delete?
        </v-card-title>

        <v-card-actions>
          <v-btn color="red" text  @click="deleteFilmType(selectedId)">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="selectedId=null; dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'FilmTypes',
    components: {},
    computed: {
    },
    data: () => ({
      isLoaded: true,
      customerId:null,
      assetGroupId: '72543f90-61de-11eb-aba8-29b524c0c604',
      selectedId: null,
      dialog: false,
      headers: [
        {text: 'SKU', sortable: true, value: 'name'},
        {text: 'Roll length (m)', sortable: true, value: 'length'},
        {text: 'Film thickness (µm)', value: 'thickness'},
        {text: 'Roll width (mm)', value: 'width'},
        {text: 'Weight per volume (kg/m³)', value: 'weightPerVolume'},
        {text: 'Weight per lineal meter (g/m)', value: 'weightPerMeter'},
        {text: '', sortable: false, value: 'id'}
      ],
      filmTypes:[],
    }),
    methods: {
      load() {
        const _this = this
        this.$tb.get('entityGroup/' + this.assetGroupId + '/assets', {pageSize: 100, page:0, type:"Roll"}).then((res)=>{
          _this.filmTypes = []
          res.data.data.forEach((item)=>{
            const details = {name:item.name, id: item.id.id}
            this.$tb.get('plugins/telemetry/ASSET/' + item.id.id + '/values/attributes').then((res2)=>{
              res2.data.forEach((item) => {
                const key = item.key
                const val = item.value
                details[key] = val
              })
              _this.filmTypes.push(details)
            })
          })
          _this.isLoaded = true
        })
      },
      deleteFilmType(id) {
        const _this = this
        this.$tb.delete('asset/' + id).then(()=>{
          _this.dialog = false
          _this.load()
        })
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style scoped>

</style>