import AWS from 'aws-sdk'

export async function getFirmwareVersions(){
  if (process.env.VUE_APP_AWS_REGION) {
    AWS.config.region = 'ap-southeast-2'
  }
  else {
    console.log('AWS region environment variable not set')
  }

  if (process.env.VUE_APP_COGNITO_CREDENTIALS) {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-southeast-2:9d3ac74f-9432-469a-a386-9d2662721e4a',
    });
  }
  else {
    console.log('AWS cognito credentials not set')
  }

  if (!process.env.VUE_APP_FIRMWARE_BUCKET_NAME) {
    console.log('AWS firmware bucket name not set')
  }

  if (!process.env.VUE_APP_FIRMWARE_BUCKET_FOLDER) {
    console.log('AWS firmware bucket folder not set')
  }

  const s3 = new AWS.S3({apiVersion: '2006-03-01',
                         params: {Bucket: process.env.VUE_APP_FIRMWARE_BUCKET_NAME}
                         })

  const availableFirmware = []
  const folder = encodeURIComponent(process.env.VUE_APP_FIRMWARE_BUCKET_FOLDER) + '/';
  const data = await s3.listObjectsV2({Delimiter: '/', Prefix:folder }).promise();
  data.Contents.forEach(item => {
    if (item.Key.endsWith('.bin')) {
      const version = item.Key.replace(folder, '').replace('.bin', '').replace('APP_v', '')
      availableFirmware.push(version)
    }
  })

  // sort numerically
  availableFirmware.sort((item1, item2) => {
    const versionRegex = /^(\d+)\.(\d+)\.(\d+)$/
    const versionParts1 = versionRegex.exec(item1)
    const version1 = parseInt(versionParts1[1])*1000000 +parseInt(versionParts1[2])*1000 + parseInt(versionParts1[3])
    const versionParts2 = versionRegex.exec(item2)
    const version2 = parseInt(versionParts2[1])*1000000 +parseInt(versionParts2[2])*1000 + parseInt(versionParts2[3])
    return version2 - version1
  })

  return availableFirmware
}
