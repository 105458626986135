<template>
  <v-card v-if="isLoaded">
    <v-card-title>
      <span class="headline">Sensor {{deviceName}}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col>
            <v-select v-model="sensorSettings.firmwareVersionAvailable" label="Firmware version"
               :items="firmwareVersions" item-text="name" item-value="name" class="form-item"></v-select>
            <Tooltip tooltip="The firmware version to be uploaded to the sensor" />
            <span>Currently running version {{sensorSettings.firmwareVersionCurrent}}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select v-model="sensorSettings.loggingVerbosity" label="Logging level"
               :items="loggingLevels" class="form-item"></v-select>
            <Tooltip tooltip="The level of detail outputted to the log" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="sensorSettings.palletDetectionSettings.minStartRotations"
              label="Minimum number of rotations" class="form-item"></v-text-field>
            <Tooltip tooltip="Minimum number of rotations required to start a wrap. There is about 100mm per rotation" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="sensorSettings.palletDetectionSettings.maxPauseTime"
              label="Maximum pause time" class="form-item"  suffix='sec'></v-text-field>
            <Tooltip tooltip="Maximum pause time allowed in a wrap before the wrap is declared finished." />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select v-model="sensorSettings.updateFrequency" label="Update frequency"
               :items="updateFrequencies" class="form-item"></v-select>
            <Tooltip tooltip="How often the sensor checks in and sends data" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-label>Data mode</v-label>
            <Tooltip tooltip="The mode in which data is gathered" />
            <v-checkbox v-for="mode in dataModes" v-model="sensorSettings.dataModes" v-bind:key="mode.value"
                :label="mode.text" :value="mode.value" dense></v-checkbox>

          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch v-model.number="sensorSettings.scheduledReboot" label="Schedule reboot"
              class="form-item"></v-switch>
            <Tooltip tooltip="If set, the sensor will reboot after the next check-in" />
          </v-col>
        </v-row>

      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cancel</v-btn>

      <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Tooltip from '@/components/Tooltip'
  import {getFirmwareVersions} from '@/lib/aws'

  export default {
    name: 'SensorSettings',
    components: {Tooltip},
    props:['sensorId', 'customerId'],
    computed:{
      
    },
    data: () => ({
      isLoaded: false,
      isValid: true,
      deviceName:'',
      firmwareVersions: [],
      loggingLevels:[
        {text:'Errors', 'value': 0},
        {text:'Warning', 'value': 1},
        {text:'Info', 'value': 2},
        {text:'Debug', 'value': 3},
      ],
      updateFrequencies:[
        {text:'5 min', 'value': 300},
        {text:'10 min', 'value': 600},
        {text:'20 min', 'value': 1200},
        {text:'30 min', 'value': 1500},
        {text:'60 min', 'value': 3600},
      ],
      dataModes:[
        {text: 'Normal', value:'normal'},
        {text: 'Wrap details', value:'raw'},
      ],
      sensorSettings: {
        firmwareVersionAvailable: '0.0.0',
        loggingVerbosity: 1,
        palletDetectionSettings: {minStartRotations: 0, maxPauseTime:650},
        scheduledReboot: false,
        updateFrequency: 3600,
        dataModes:[],
        dataMode: 1,
      },
      rules: {
        required: [val => {
          return (val) ? true : 'Required'
        }]
      }
    }),
    methods: {
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      save(){
        const _this = this
        const data = {
          firmwareVersionAvailable: this.sensorSettings.firmwareVersionAvailable,
          loggingVerbosity: this.sensorSettings.loggingVerbosity,
          palletDetectionSettings: {
            minStartRotations: this.sensorSettings.palletDetectionSettings.minStartRotations,
            maxPauseTime: this.sensorSettings.palletDetectionSettings.maxPauseTime,
          },
          scheduledReboot: this.sensorSettings.scheduledReboot,
          updateFrequency: this.sensorSettings.updateFrequency,
          dataMode: this.packBits(this.sensorSettings.dataModes),
        }

        _this.$tb.post('plugins/telemetry/DEVICE/' + this.sensorId + '/attributes/SHARED_SCOPE', data).then(()=>{
          _this.$emit('close')
        })
      },
      unpackBits(val){
        const bitWidth = 3
        const data = [...Array(bitWidth)].map((x,i)=>val>>i&1) // unpacks an integer to an array of 0's and 1's
        const result = []
        if (data[0]) { result.push('normal')}
        if (data[1]) { result.push('raw')}
        if (data[2]) { result.push('other')}
        return result
      },
      packBits(val){
        // create a bit packed integer
        let result = 0
        if (val.includes('normal')) { result += 1}
        if (val.includes('raw')) { result += 2}
        if (val.includes('other')) { result += 4}
        return result
      },
    },
    mounted() {
      const _this = this
      getFirmwareVersions().then((fw) => {
        _this.firmwareVersions = fw
      })

      // load sensor name
      _this.$tb.get('device/' + _this.sensorId).then((res2)=>{
        _this.deviceName = res2.data.name
      })

      // load sensor details
      _this.$tb.get('plugins/telemetry/DEVICE/' + _this.sensorId + '/values/attributes').then((res2)=>{
        res2.data.forEach((item) => {
          const key = item.key
          const val = item.value
          _this.sensorSettings[key] = val
        })
        _this.isLoaded=true

        _this.sensorSettings['dataModes'] = _this.unpackBits(_this.sensorSettings['dataMode'])
      })
    }
  }
</script>

<style scoped>
.form-item {
  width: 260px;
  display: inline-block;
}

.v-input--checkbox {
  padding:0;
  margin:0
}

.v-input--checkbox >>> .v-messages{
  display:none;
}
</style>