<template>
  <v-card v-if="isLoaded">
    <v-card-title>
      <span class="headline" >Data Splitter</span>
    </v-card-title>

    <v-card-text>
    <p>This tool will split a record in two. Clicking the cut icon will instantly slice the record.
       Warning:This cannot be undone</p>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col>
            <v-text-field v-model="minLength" label="Minimum wrap length"></v-text-field>
             <v-data-table :headers="headers" :items="filteredData" :items-per-page="500">
               <template v-slot:item.id="{ item }">
                 <v-btn @click="cut2(item)">
                   <v-icon>mdi-scissors-cutting</v-icon><span>1/2</span>
                 </v-btn>
                 <v-btn @click="cut3(item)">
                   <v-icon>mdi-scissors-cutting</v-icon><span>1/3</span>
                 </v-btn>
                 <v-btn @click="cut5(item)">
                   <v-icon>mdi-scissors-cutting</v-icon><span>1/5</span>
                 </v-btn>
               </template>

             </v-data-table>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar">Saving</v-snackbar>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'WrapperDataSplitter',
    components: {},
    props:['wrapper'],
    computed:{
      filteredData(){
        if (this.minLength != '') {
          const min = parseInt(this.minLength)
          const filteredData = []
          this.data.forEach(row =>{
            if (row.wrapLength > min) {
              filteredData.push(row)
            }
          })
          return filteredData
        }
        else {
          return this.data
        }
      }

    },
    data: () => ({
      isLoaded: false,
      isValid: true,
      headers: [
        {text: 'Time', value: 'ts' },
        {text: 'Wrap Length', value: 'wrapLength' },
        {text: 'Wrap Weight', value: 'wrapWeight' },
        {text: 'Duration', value: 'duration'},
        {text: '', value: 'id'}
      ],
      data: {},
      minLength: '',
      snackbar:false
    }),
    methods: {
      save(data){
        this.$tb.post('plugins/telemetry/ASSET/'+this.wrapper.id.id+'/timeseries/scope', data).then(()=>{
          // do nothing
        })
      },
      getWraps(){
        const d = new Date()
        const to = d.getTime()
        d.setDate(-1)
        const from = d.getTime()

        const data = {limit:1000, keys:"wrapLength,wrapWeight,duration", startTs: from,endTs:to}
        this.$tb.get('plugins/telemetry/ASSET/'+this.wrapper.id.id+'/values/timeseries', data).then((res)=>{
          console.log(res)
          const details = {}
          const keys = ['wrapLength','wrapWeight','duration']
          keys.forEach(k => {
            if (res.data[k]){
              res.data[k].forEach((item)=>{
                if (!details[item.ts]) {
                  const ts = new Date(item.ts)
                  details[item.ts] = {ts: ts.toString().split('G')[0],
                                      id: item.ts
                                     }
                }
                details[item.ts][k] = item.value
              })
            }
          })
          this.data = Object.values(details)
          this.isLoaded=true
        })
      },
      cut2(item){
        const duration = Math.round(parseInt(item.duration)/2)
        const wrapLength = Math.round(parseInt(item.wrapLength)/2)
        const wrapWeight = Math.round(parseInt(item.wrapWeight)/2)

        const gap = 60*1000 //allow this much gap between wraps
        const newItem1 = {
          "ts":item.id - (1000*duration + gap),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem2 = {
          "ts":item.id,
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        this.save(newItem1)
        this.save(newItem2)
        this.reload()
      },
      cut3(item){ // cut into 3 pieces
        const gap = 60 //allow this much gap between wraps (sec)
        const duration = Math.round(parseInt(item.duration)/3) - gap
        const wrapLength = Math.round(parseInt(item.wrapLength)/3)
        const wrapWeight = Math.round(parseInt(item.wrapWeight)/3)

        const newItem1 = {
          "ts":item.id - (1000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem2 = {
          "ts":item.id - (2000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem3 = {
          "ts":item.id,
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        this.save(newItem1)
        this.save(newItem2)
        this.save(newItem3)
        this.reload()
      },
      cut5(item){ // cut into 5 pieces
        const gap = 60 //allow this much gap between wraps (sec)
        const duration = Math.round(parseInt(item.duration)/5) - gap
        const wrapLength = Math.round(parseInt(item.wrapLength)/5)
        const wrapWeight = Math.round(parseInt(item.wrapWeight)/5)

        const newItem1 = {
          "ts":item.id - (1000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem2 = {
          "ts":item.id - (2000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem3 = {
          "ts":item.id - (3000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem4 = {
          "ts":item.id - (4000*(duration + gap)),
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        const newItem5 = {
          "ts":item.id,
          "values": {
            "duration": duration,
            "wrapLength": wrapLength,
            "wrapWeight": wrapWeight,
          }
        }
        this.save(newItem1)
        this.save(newItem2)
        this.save(newItem3)
        this.save(newItem4)
        this.save(newItem5)
        this.reload()
      },
      reload(){
        this.snackbar = true
        setTimeout(() => {
          this.getWraps()
          this.snackbar = false
        }, 1000)
      }
    },
    mounted() {
      this.getWraps()
//       const newItem2 = {
//        "ts":1629421769776,
//        "values": {
//          "duration": 1000,
//          "wrapLength": 500,
//          "wrapWeight": 250,
//        }
//      }
      //this.save(newItem2)

    }
  }
</script>

<style scoped>
.form-item {
  width: 260px;
  display: inline-block;
}
</style>