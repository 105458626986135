<template>
    <v-card v-if="isLoaded">
      <v-card-title>
        <span class="headline">Wrapper Linkage</span>
      </v-card-title>
      <v-card-text>
        <span>Connect the wrapper to a sensor</span>
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <v-text-field v-model="deviceName" label="Sensor"
                :error-messages="errors" class="form-item"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="unlink" color="red" v-if="originalRelation">Unlink</v-btn>
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: 'WrapperRelationships',
    props:['wrapper', 'customerId'],
    computed: {
    },
    watch: {
      deviceName(val) { // when the device name changes
        const _this = this
        // see if the device name exists
        _this.$tb.get('entityGroup/'+ _this.deviceEntityGroupId +'/devices', {pageSize: 10, page:0, textSearch:val}).then((res)=>{
          const onlyOne = res.data.data.length === 1
          const nameMatches = onlyOne && res.data.data[0].name === val

          if (nameMatches) {
            const deviceId = res.data.data[0].id.id
            // load the relationship of the device to make sure it is not already linked elsewhere
            _this.$tb.get('relations/info', {fromType: 'DEVICE', fromId: deviceId}).then((res2)=>{
              if (res2.data.length === 0) { // device is not currently attached to a wrapper
                _this.deviceId = deviceId
                _this.errors = ''
              }
              else if (res2.data[0].to.id == _this.wrapper.id.id) { // Currently attached to this wrapper
                _this.errors = ''
              }
              else {
                _this.errors = 'Currently attached to another wrapper'
              }
            })
          }
          else {
            _this.errors = 'Sensor not found'
          }
        })

        return true
      }
    },
    data: () => ({
      isLoaded: false,
      isValid: true,
      deviceId:null,
      originalRelation:null,
      errors:'',
      deviceName: '',
      deviceEntityGroupId: '7fcaafb0-3ff1-11eb-9025-c964801605d3'
    }),
    methods: {
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      save() {
        const _this = this
        _this.validateForm()
        if (this.isValid) {
          const data = { // data to save
            from: {entityType: "DEVICE", id: _this.deviceId},
            to: {entityType: "ASSET", id: _this.wrapper.id.id},
            type: "Contains",
            typeGroup: "COMMON"
          }

          if (_this.originalRelation) {
            const del = { // data to delete
              fromId:  _this.originalRelation.from.id,
              fromType: _this.originalRelation.from.entityType,
              toId: _this.originalRelation.to.id,
              toType:  _this.originalRelation.to.entityType,
              relationType:  _this.originalRelation.type
            }

            _this.$tb.delete('relation', del).then(()=>{ // delete the old relationship
              _this.$tb.post('relation', data).then(()=>{ // create a new relationship
                _this.$emit('close')
              })
            })
          }
          else {
            _this.$tb.post('relation', data).then(()=>{ // create a new relationship
              _this.$emit('close')
            })
          }
        }
      },
      unlink(){
        const _this = this
        if (_this.originalRelation) {
          const del = { // data to delete
            fromId:  _this.originalRelation.from.id,
            fromType: _this.originalRelation.from.entityType,
            toId: _this.originalRelation.to.id,
            toType:  _this.originalRelation.to.entityType,
            relationType:  _this.originalRelation.type
          }

          _this.$tb.delete('relation', del).then(()=>{ // delete the old relationship
            _this.$emit('close')
          })
        }
        else {
          console.log('Error: Linkage not found')
        }
      }
    },
    mounted() {
      const _this = this
      if (_this.wrapper !== null) {
        _this.$tb.get('relations/info', {toType: 'ASSET', toId: this.wrapper.id.id}).then((res)=>{ // load wrapper relationships
          if (res.data.length > 0) { // if there is a wrapper is linked to a sensor
            _this.deviceName = res.data[0].fromName
            _this.deviceId = res.data[0].from.id
            _this.originalRelation = {...res.data[0]} // used for deleting
          }
           _this.isLoaded=true
        })
      }
    }
  }
</script>

<style scoped>
.form-item {
  max-width:500px
}
</style>