<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline">Wrappers for {{customerName}}</span>
      </v-card-title>

      <v-card-text>
        <v-btn @click="addWrapper"><v-icon>mdi-plus</v-icon>Add Wrapper</v-btn>
        <v-data-table :headers="headers" :items="wrapperTable" :items-per-page="500" hide-default-footer :sort-by="['name']">
          <template v-slot:item.id="{ item }">
            <v-btn @click="editWrapperSettings(item.id)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="editWrapperRelationships(item.id)" icon>
              <v-icon>mdi-cellphone-link</v-icon>
            </v-btn>
            <v-btn @click="calibrateWrapper(item.id)" icon>
              <v-icon>mdi-calculator-variant</v-icon>
            </v-btn>
            <v-btn @click="editSensorSettings(item.id)" icon v-if="item.deviceName !== 'None'">
              <v-icon>mdi-circle-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="splitData(item.id)" icon>
              <v-icon>mdi-scissors-cutting</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="settingsDialog" max-width="350">
       <WrapperSettings :wrapper="selectedWrapper" :customerId="customerId" :key="Math.random()" @close="loadWrappers();settingsDialog=false"/>
    </v-dialog>

    <v-dialog v-model="relationshipsDialog" max-width="350">
      <WrapperRelationships :wrapper="selectedWrapper" :key="Math.random()" :customerId="customerId" @close="loadWrappers();relationshipsDialog=false" />
    </v-dialog>

    <v-dialog v-model="calibrationDialog" max-width="450">
      <WrapperCalibration :wrapper="selectedWrapper" :key="Math.random()"  @close="calibrationDialog=false" />
    </v-dialog>

    <v-dialog v-model="sensorSettingsDialog" max-width="350">
      <SensorSettings v-if="selectedWrapper" :sensorId="selectedWrapper.deviceId" :key="Math.random()"  @close="sensorSettingsDialog=false" />
    </v-dialog>

    <v-dialog v-model="dataSplitterDialog" max-width="80%">
      <WrapperDataSplitter v-if="selectedWrapper" :wrapper="selectedWrapper" :key="Math.random()"  @close="dataSplitterDialog=false" />
    </v-dialog>
  </v-container>
</template>

<script>
  import WrapperRelationships from '@/components/WrapperRelationships'
  import WrapperSettings from '@/components/WrapperSettings'
  import WrapperCalibration from '@/components/WrapperCalibration'
  import SensorSettings from '@/components/SensorSettings'
  import WrapperDataSplitter from '@/components/WrapperDataSplitter'

  export default {
    name: 'Wrappers',
    components: {WrapperRelationships, WrapperSettings, WrapperCalibration, SensorSettings,WrapperDataSplitter},
    computed:{
      wrapperTable(){
        const res = []
        this.wrappers.forEach((item)=>{
          res.push({id: item.id.id,
                    name: item.name,
                    deviceName:item.deviceName
                    })
        })
        return res
      }
    },
    data: () => ({
      isLoaded: false,
      customerId:null,
      relationshipsDialog: false,
      settingsDialog: false,
      calibrationDialog: false,
      sensorSettingsDialog:false,
      dataSplitterDialog:false,
      selectedWrapper: null,
      selectedWrapperId: null,
      customerName:'',
      headers: [
        {text: 'Wrapper', sortable: true, value: 'name'},
        {text: 'Sensor', sortable: true, value: 'deviceName'},
        {text: '', sortable: false, value: 'id'}
      ],
      wrappers:[],
    }),
    methods: {
      loadCustomer(){
        const _this = this
        this.$tb.get('customer/' + this.customerId).then((res)=>{
          _this.customerName = res.data.name
        })
      },
      loadWrappers(){
        const _this = this
        this.$tb.get('customer/' + this.customerId + '/assets', {pageSize: 100, page:0, type:"Wrapper"}).then((res)=>{
          _this.wrappers = res.data.data
          _this.loadDevices()
          _this.isLoaded = true
        })
      },
      loadDevices(){
        const _this = this
        _this.wrappers.forEach(wrapper => {
          _this.$tb.get('relations/info', {toType: 'ASSET', toId: wrapper.id.id}).then((res)=>{ // load wrapper relationships
            if (res.data.length > 0) { // if there is a wrapper is linked to a sensor
              _this.$set(wrapper, 'deviceName', res.data[0].fromName)
              _this.$set(wrapper, 'deviceId',  res.data[0].from.id)
            }
            else {
               _this.$set(wrapper, 'deviceName', 'None')
               _this.$set(wrapper, 'deviceId', null)
            }
          })

        })
      },
      addWrapper(){
        this.selectedWrapper = null
        this.selectedWrapperId = Math.random()
        this.settingsDialog = true
      },
      editWrapperSettings(wrapperId) {
        this.selectedWrapper = this.findWrapper(wrapperId)
        this.selectedWrapperId = this.selectedWrapper.id.id
        this.settingsDialog = true
      },
      editSensorSettings(wrapperId) {
        this.selectedWrapper = this.findWrapper(wrapperId)
        this.selectedWrapperId = this.selectedWrapper.id.id
        this.sensorSettingsDialog = true
      },
      editWrapperRelationships(wrapperId) {
        this.selectedWrapper = this.findWrapper(wrapperId)
        this.selectedWrapperId = this.selectedWrapper.id.id
        this.relationshipsDialog = true
      },
      calibrateWrapper(wrapperId) {
        this.selectedWrapper = this.findWrapper(wrapperId)
        this.selectedWrapperId = this.selectedWrapper.id.id
        this.calibrationDialog = true
      },
      splitData(wrapperId) {
        this.selectedWrapper = this.findWrapper(wrapperId)
        this.selectedWrapperId = this.selectedWrapper.id.id
        this.dataSplitterDialog = true
      },
      findWrapper(wrapperId){
        for (let i=0; i<this.wrappers.length; i++) {
          if (this.wrappers[i].id.id == wrapperId) {
            return this.wrappers[i]
          }
        }
      }
    },
    mounted() {
      this.customerId = this.$route.params.id
      this.loadWrappers()
      this.loadCustomer()
    }
  }
</script>

<style scoped>

</style>