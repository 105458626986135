import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import Thingsboard from "./plugins/thingsboard";

import Customers from './pages/Customers'
import Wrappers from './pages/Wrappers'
import FilmTypeSettings from './pages/FilmTypeSettings'
import FilmTypes from './pages/FilmTypes'
import Firmware from './pages/Firmware'
import Sensors from './pages/Sensors'

const APP_VERSION = require('../package.json').version;
Vue.prototype.$version = APP_VERSION

const routes = [
  {path: '/', component: Sensors}, // default
  {path: '/sensors', component: Sensors},
  {path: '/customers', component: Customers},
  {path: '/customer/:id/wrappers', component: Wrappers},
  {path: '/film-types', component: FilmTypes},
  {path: '/film-type/add', component: FilmTypeSettings},
  {path: '/film-type/:id/edit', component: FilmTypeSettings},
  {path: '/firmware', component: Firmware},
];

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Thingsboard);

const router = new VueRouter({routes});

new Vue({
  router,
  vuetify,
//  created(){
   // this.$tb.authorize().then(()=>{
    //  console.log('Authorized')
   // })
  //},
  render: h => h(App)
}).$mount('#app')
