<template>
  <v-tooltip bottom class="tooltip" :max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" dark v-bind="attrs" v-on="on" x-small>
        mdi-help
      </v-icon>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>


<script>
  export default {
    name: 'Tooltip',
    props:['tooltip'],
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    }
  }
</script>

<style scoped>
.tooltip {
  padding-left: 10px;
  display: inline-block;
}
</style>