<template>
  <v-container fluid v-if="isLoaded">
    <v-card>
      <v-card-title>
        <span class="headline">Sensors</span>
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="headers" :items="sensorTable" :items-per-page="500" hide-default-footer :sort-by="['name']">
          <template v-slot:item.id="{ item }">
            <v-btn @click="editSensorSettings(item.id)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="editSensorRelationships(item.id)" icon>
              <v-icon>mdi-cellphone-link</v-icon>
            </v-btn>
            <v-btn @click="calibrateWrapper(item.wrapperId)" icon  v-if="item.wrapperName !== ''">
              <v-icon>mdi-calculator-variant</v-icon>
            </v-btn>
            <v-btn @click="editWrapperSettings(item.wrapperId)" icon  v-if="item.wrapperName !== ''">
              <v-icon>mdi-paper-roll</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>


    <v-dialog v-model="sensorSettingsDialog" max-width="350">
      <SensorSettings :sensorId="selectedSensorId" :key="Math.random()"  @close="sensorSettingsDialog=false" />
    </v-dialog>

    <v-dialog v-model="relationshipsDialog" max-width="350">
      <SensorRelationships :sensor="selectedSensor" :key="Math.random()" @close="loadSensors();relationshipsDialog=false" />
    </v-dialog>


    <v-dialog v-model="calibrationDialog" max-width="450">
      <WrapperCalibration :wrapper="selectedWrapper" :key="Math.random()"  @close="calibrationDialog=false" />
    </v-dialog>

    <v-dialog v-model="wrapperSettingsDialog" max-width="350">
       <WrapperSettings :wrapper="selectedWrapper" :key="Math.random()" @close="loadSensors();wrapperSettingsDialog=false"/>
    </v-dialog>


  </v-container>
</template>

<script>
  import SensorRelationships from '@/components/SensorRelationships'
  import SensorSettings from '@/components/SensorSettings'
  import WrapperSettings from '@/components/WrapperSettings'
  import WrapperCalibration from '@/components/WrapperCalibration'

  export default {
    name: 'Sensors',
    components: { SensorSettings,WrapperSettings,WrapperCalibration,SensorRelationships},
    computed:{
      sensorTable(){
        const res = []
        this.sensors.forEach((item)=>{
          res.push({id: item.id.id,
                    name: item.name,
                    wrapperName:item.wrapperName,
                    wrapperId:item.wrapperId
                    })
        })
        return res
      }
    },
    data: () => ({
      isLoaded: false,
      customerId:null,
      relationshipsDialog: false,
      wrapperSettingsDialog: false,
      calibrationDialog: false,
      sensorSettingsDialog:false,
      selectedSensor: null,
      selectedSensorId: null,
      selectedWrapper: null,
      customerName:'',
      headers: [
        {text: 'Sensor', sortable: true, value: 'name'},
        {text: 'Wrapper', sortable: true, value: 'wrapperName'},
        {text: '', sortable: false, value: 'id'}
      ],
      sensors:[],
    }),
    methods: {
      loadSensors(){
        const _this = this
        this.$tb.get('tenant/devices', {pageSize:1000, page:0}).then((res)=>{
          _this.sensors = res.data.data
          _this.isLoaded = true
          _this.loadWrappers()
        })
      },
      loadWrappers(){
        const _this = this
        _this.sensors.forEach(sensor => {
          _this.$tb.get('relations/info', {fromType: 'DEVICE', fromId: sensor.id.id}).then((res)=>{ // load sensor relationships
            if (res.data.length > 0) { // if there is a sensor is linked to a sensor
              _this.$set(sensor, 'wrapperName', res.data[0].toName)
              _this.$set(sensor, 'wrapperId',  res.data[0].to.id)
            }
            else {
               _this.$set(sensor, 'wrapperName', '')
               _this.$set(sensor, 'wrapperId', null)
            }
          })

        })
      },
      editSensorSettings(sensorId) {
        this.selectedSensor = this.findSensor(sensorId)
        this.selectedSensorId = this.selectedSensor.id.id
        this.sensorSettingsDialog = true
      },
      editSensorRelationships(sensorId) {
        this.selectedSensor = this.findSensor(sensorId)
        this.relationshipsDialog = true
      },
      editWrapperSettings(wrapperId) {
        const _this = this
        this.$tb.get('asset/' + wrapperId).then((res)=>{
          _this.selectedWrapper = res.data
          _this.wrapperSettingsDialog = true
        })
      },
      calibrateWrapper(wrapperId) {
        const _this = this
        this.$tb.get('asset/' + wrapperId).then((res)=>{
          _this.selectedWrapper = res.data
          _this.calibrationDialog = true
        })
      },
      findSensor(sensorId){
        for (let i=0; i<this.sensors.length; i++) {
          if (this.sensors[i].id.id == sensorId) {
            return this.sensors[i]
          }
        }
      },
    },
    mounted() {
      this.loadSensors()
    }
  }
</script>

<style scoped>

</style>