<template>
    <v-card v-if="isLoaded">
      <v-card-title>
        <span class="headline">Sensor Linkage</span>
      </v-card-title>
      <v-card-text>
        <span>Connect the sensor to a wrapper</span>
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <v-select v-model="customerId" label="Customer" :items="customers" item-value="id" item-text="name"
                :error-messages="errors" class="form-item" @change="loadWrappers"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="wrapperId" label="Wrapper" :items="wrappers" item-value="id" item-text="name"
                :error-messages="errors" class="form-item"></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="unlink" color="red" v-if="originalRelation">Unlink</v-btn>
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn :disabled="!isValid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: 'SensorRelationships',
    props:['sensor'],
    computed: {
    },
    watch: {
    },
    data: () => ({
      isLoaded: false,
      isValid: true,
      customerId: null,
      customers: [],
      wrappers: [],
      wrapperId:null,
      originalRelation:null,
      errors:'',
    }),
    methods: {
      loadWrappers(){
        const _this = this
        this.$tb.get('customer/' + this.customerId + '/assets', {pageSize: 100, page:0, type:"Wrapper"}).then((res)=>{
          _this.wrappers = []
          res.data.data.forEach((item)=>{
            _this.$tb.get('relations/info', {toType: 'ASSET', toId: item.id.id}).then((res)=>{ // load device relationships
              if (res.data.length == 0 || (res.data.length > 0 && res.data[0].from.id == _this.sensor.id.id)) { // if there is no wrapper is linked to a sensor
                _this.wrappers.push({id:item.id.id,
                                     name: item.name
                                    })
              }
            })
          })
        })
      },
      validateForm() {
        this.isValid = this.$refs.form.validate()
      },
      save() {
        const _this = this
        _this.validateForm()
        if (this.isValid) {
          const data = { // data to save
            from: {entityType: "DEVICE", id: _this.sensor.id.id},
            to: {entityType: "ASSET", id: _this.wrapperId},
            type: "Contains",
            typeGroup: "COMMON"
          }

          if (_this.originalRelation) {
            const del = { // data to delete
              fromId:  _this.originalRelation.from.id,
              fromType: _this.originalRelation.from.entityType,
              toId: _this.originalRelation.to.id,
              toType:  _this.originalRelation.to.entityType,
              relationType:  _this.originalRelation.type
            }

            _this.$tb.delete('relation', del).then(()=>{ // delete the old relationship
              _this.$tb.post('relation', data).then(()=>{ // create a new relationship
                _this.$emit('close')
              })
            })
          }
          else {
            _this.$tb.post('relation', data).then(()=>{ // create a new relationship
              _this.$emit('close')
            })
          }
        }
      },
      unlink(){
        const _this = this
        if (_this.originalRelation) {
          const del = { // data to delete
            fromId:  _this.originalRelation.from.id,
            fromType: _this.originalRelation.from.entityType,
            toId: _this.originalRelation.to.id,
            toType:  _this.originalRelation.to.entityType,
            relationType:  _this.originalRelation.type
          }

          _this.$tb.delete('relation', del).then(()=>{ // delete the old relationship
            _this.$emit('close')
          })
        }
        else {
          console.log('Error: Linkage not found')
        }
      }
    },
    mounted() {
      const _this = this
      // Load customers
      _this.$tb.get('customers', {pageSize: 100, page:0}).then((res)=>{
        _this.customers = []
        res.data.data.forEach((item)=>{
          _this.customers.push({id:item.id.id,
                                name: item.name
                               })
        })
        _this.isLoaded = true
      })

      // load default wrapper and customer
      _this.$tb.get('relations/info', {fromType: 'DEVICE', fromId: _this.sensor.id.id}).then((res)=>{ // load device relationships
        if (res.data.length > 0) { // if there is wrapper linked to a sensor
          _this.wrapperId = res.data[0].to.id
          _this.originalRelation = res.data[0]
          _this.$tb.get('asset/'+_this.wrapperId).then((res)=>{ // load customer for this wrapper
            _this.customerId = res.data.customerId.id
            _this.loadWrappers()
          })
        }
      })

    }
  }
</script>

<style scoped>
.form-item {
  max-width:500px
}
</style>